<template>
  <div class="help">
    <div class="title">Не нашли нужный товар?</div>
    <p>Вы можете заказать консультацию по подбору нужного товара у наших специалистов</p>

    <base-button
      class="btn-size"
      text="Заказать консультацию"
      @click="isModal = true"
    ></base-button>
  </div>
</template>

<script setup lang="ts">
import {computed} from '#imports';
import BaseButton from '@/components/Base/Button.vue';

const props = defineProps({
  modelValue: {type: Boolean, required: false},
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const isModal = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});
</script>
