<template>
  <section class="equipmenu-top">
    <div class="container">
      <bread-crumbs
        class="breadcrumbs--tablet-gap-small breadcrumbs--on-item-mobile"
        :breadcrumbs-list="equipmentStore.breadcrumbsList"
      ></bread-crumbs>
      <h1 class="catalog__title">{{ rootStore.generalMeta?.h1 || equipmentStore.titlePage }}</h1>

      <template v-if="normalizePath($route.path) !== '/catalog/'">
        <nuxt-link class="back" to="/catalog">
          <svg
            width="19"
            height="8"
            viewBox="0 0 19 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.646446 3.64645C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM19 3.5L1 3.5V4.5L19 4.5V3.5Z"
              fill="#4B6286"
            />
          </svg>
          Назад
        </nuxt-link>
      </template>
    </div>
  </section>

  <section class="equipmenu">
    <div class="container">
      <detailed-news-aside class="equipmenu-sidebar">
        <template #above>
          <equipment-aside :is-scroll="isScroll"></equipment-aside>
        </template>
        <equipment-aside-help v-model="isConsulationModalShown"></equipment-aside-help>
      </detailed-news-aside>

      <div class="equipmenu_in">
        <slot></slot>
      </div>
    </div>
  </section>
  <contact-consult v-model="isConsulationModalShown"></contact-consult>
</template>

<script setup lang="ts">
import 'sass/equipment/equipment.scss';

import {ref} from '#imports';
import BreadCrumbs from '@/components/breadcrumbs/index.vue';
import ContactConsult from '@/components/contact/consult.vue';
import DetailedNewsAside from '@/components/detailed-news/aside/index.vue';
import EquipmentAsideHelp from '@/components/equipment/aside/help.vue';
import EquipmentAside from '@/components/equipment/aside/index.vue';
import {useApiMeta} from '@/composables/useMeta';
import {useEquipmentStore} from '@/stores/equipment';
import {useRootStore} from '@/stores/root';
import {normalizePath} from '@/utils/router';

defineProps({
  isScroll: {type: Boolean, required: true},
});

const rootStore = useRootStore();
const equipmentStore = useEquipmentStore();

const isConsulationModalShown = ref(false);

useApiMeta();
</script>
