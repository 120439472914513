<template>
  <aside>
    <div class="in">
      <ul v-if="sideBarList.length">
        <equipment-aside-link
          v-for="(item, index) in sideBarList"
          :key="index"
          :url="item.url || ''"
          :name="item.name || ''"
          :index="index"
          :is-scroll="isScroll"
        ></equipment-aside-link>
      </ul>
    </div>
  </aside>
</template>

<script setup lang="ts">
import 'sass/equipment/sidebar.scss';

import {computed} from '#imports';
import {useEquipmentStore} from '@/stores/equipment';
import EquipmentAsideLink from './link.vue';

defineProps({
  isScroll: {type: Boolean, required: true},
});

const equipmentStore = useEquipmentStore();

const sideBarList = computed(() => equipmentStore.sideBarList);
</script>
