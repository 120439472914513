<template>
  <li>
    <nuxt-link v-if="!isScroll" :to="url || '#'" class="link" :class="{disabled: !url}">
      {{ name }}
    </nuxt-link>
    <span
      v-else
      class="link"
      :class="{active: equipmentStore.isActiveLink === name}"
      @click="moveToBlock()"
      >{{ name }}</span
    >
  </li>
</template>

<script setup lang="ts">
import 'sass/equipment/sidebarLink.scss';

import {useEventListener} from '@vueuse/core';
import {onMounted, ref, useRouter} from '#imports';
import {type PropType} from '#imports';
import {useEquipmentStore} from '@/stores/equipment';

const props = defineProps({
  index: {type: Number, required: true},
  url: {type: String as PropType<string | null>, required: true},
  isScroll: {type: Boolean, required: true},
  name: {type: String, required: true},
});

const equipmentStore = useEquipmentStore();
const router = useRouter();

const isMove = ref(false);

const moveToBlock = () => {
  const el = document.querySelectorAll('.equipment-item--big')[props.index] as HTMLElement;

  const header: number = document.querySelector('.scrolled-bar')
    ? document.querySelector('.scrolled-bar')?.clientHeight || 0
    : 0;
  void router.push(`#${el.id}`);
  window.scrollTo({
    top: el.offsetTop - 19 - header,
    left: 0,
    behavior: 'smooth',
  });
  isMove.value = true;
  setTimeout(() => {
    equipmentStore.isActiveLink = props.name;
  }, 10);
  setTimeout(() => {
    isMove.value = false;
  }, 1000);
};

const handleScrollBody = () => {
  const hScroll = window.scrollY;
  const header: number = document.querySelector('.scrolled-bar')
    ? document.querySelector('.scrolled-bar')?.clientHeight || 0
    : 0;
  const els = document.querySelectorAll('.equipment-item--big');
  document.querySelectorAll('.equipment-item--big').forEach((item, i) => {
    if (hScroll + 20 + header > (item as HTMLElement).offsetTop && !isMove.value)
      equipmentStore.isActiveLink = els[i].querySelector('.title')?.innerHTML || '';
  });
};

onMounted(() => {
  useEventListener(window, 'scroll', handleScrollBody);
});
</script>
